import type { CountryISO } from '@/shared/types'

import { state } from './geo.store'

import { ipGeoGet } from '../api/ipGeoGet.api'

export function geoGet(): Promise<CountryISO | undefined> {
  return ipGeoGet()
}

export async function countryGet(): Promise<CountryISO | undefined> {
  if (state.countryCode) return state.countryCode
  state.countryCode = await geoGet()
  return state.countryCode
}

export async function isUSA(): Promise<boolean> {
  return (await countryGet()) === 'US'
}

export async function isGB(): Promise<boolean> {
  return (await countryGet()) === 'GB'
}

export async function isCY(): Promise<boolean> {
  return (await countryGet()) === 'CY'
}

export async function isBGorGB(): Promise<boolean> {
  const country = await countryGet()
  return country === 'GB' || country === 'BG'
}
