import type { CountryISO } from '@/shared/types'

type ApiResponse = {
  geo: {
    countryCode: CountryISO
  }
}

const API_REQUEST_PATH = '/ip/geo'

export async function ipGeoGet() {
  const response = await useApiApp<ApiResponse>(API_REQUEST_PATH)
  return (response.isOk && response.data?.geo?.countryCode) || undefined
}
